<template>
  <footer class="mt-5 pt-4">
    <div class="container py-4">
      <div class="row">
        <div class="col-md-4 mb-3">
          <img src="../../assets/home/logo.webp" alt="" />
        </div>
        <div class="col-md-3 mb-3">
          <i class="fas fa-globe fa-fw"></i>
          <a href="https://all-inedu.com" target="_blank">www.all-inedu.com</a>
          <br />
          <i class="fab fa-instagram fa-fw"></i>
          <a href="https://www.instagram.com/allineduspace/" target="_blank"
            >@allineduspace</a
          >
        </div>
        <div class="col-md-5 mb-3 ps-md-5">
          <i class="fas fa-mobile-alt fa-fw"></i>
          <a href="https://wa.me/6281808081363" target="_blank"
            >+62 818-0808-1363</a
          >
          /
          <a href="https://wa.me/6287860811413" target="_blank"
            >+62 878-6081-1413</a
          >
          <br />
          <i class="fas fa-envelope fa-fw"></i>
          <a href="mailto:info@all-inedu.com" target="_blank"
            >info@all-inedu.com</a
          >
        </div>
        <div class="col-md-12">
          <hr class="my-2" />
        </div>
        <div class="col-md-4 mb-3">
          Jl Jeruk Kembar Blok Q9, No. 15 <br />
          Srengseng, Kembangan <br />
          Jakarta Barat 11630, Indonesia
        </div>
        <div class="col-md-3 mb-3">
          #itsALLintheprep <br />
          #TakeOnYourFuture
        </div>
        <div class="col-md-5 mb-3 ps-md-5">
          Copyright © 2021 · ALL-in Eduspace. <br />
          All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  text-align: left;
  background: #243672;
  color: #fff;
  font-size: 15px !important;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer .social-media {
  font-size: 15px;
}

footer img {
  width: 50%;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "Footer",
  components: {},
};
</script>